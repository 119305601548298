<template>
  <CModal id="CreateLogisticsOrder" class="ActionModel" :show.sync="Toggle" :centered="true" :closeOnBackdrop="!Submit"
          @update:show="HandleHideModal">
    <template v-if="!Loading && Data.Logistics.System !== 'CUSTOM'">
      <CRow form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Order.LogisticsSystem') }}
        </CCol>
        <CCol sm="9" class="d-flex align-items-center">
          {{ $t('Logistics.LogisticsSystem.' + Data.Logistics.System) }}
        </CCol>
      </CRow>
      <CRow form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Order.LogisticsType') }}
        </CCol>
        <CCol sm="9" class="d-flex align-items-center">
          {{ $t('Logistics.LogisticsMethods.' + Data.Logistics.Method) }}
        </CCol>
      </CRow>
      <CInput :label="'收件人'" v-model="LogisticsData.MemberInfo.ReceiverName" horizontal/>
      <CInput :label="'收件人Email'" v-model="LogisticsData.MemberInfo.ReceiverEmail" horizontal/>
      <CSelect horizontal :label="'收件人國家'" :options="CountryOptions" v-model="LogisticsData.MemberInfo.ReceiverCountry" :value.sync="LogisticsData.MemberInfo.ReceiverCountry" :placeholder="$t('Global.PleaseSelect')"/>
      <CInput id="ReceiverCity" :label="'收件人城市'" v-model="LogisticsData.MemberInfo.ReceiverCity" horizontal/>
      <CInput id="ReceiverArea" :label="'收件人地區/州'" v-model="LogisticsData.MemberInfo.ReceiverArea" horizontal/>
      <CInput id="ReceiverZipCode" :label="'收件人郵遞區號'" v-model="LogisticsData.MemberInfo.ReceiverZipCode" horizontal/>
      <CInput id="ReceiverAddress" :label="'收件人地址'" v-model="LogisticsData.MemberInfo.ReceiverAddress" horizontal/>
      <CInput id="ReceiverAddress2" :label="'收件人地址2'" v-model="LogisticsData.MemberInfo.ReceiverAddress2" horizontal/>
      <CInput id="ReceiverAddress3" :label="'收件人地址3'" v-model="LogisticsData.MemberInfo.ReceiverAddress3" horizontal/>
      <CRow form class="form-group mb-0">
        <CCol tag="label" sm="3" class="col-form-label">
          預定配送日期
        </CCol>
        <CCol sm="9">
          <v-date-picker v-model="LogisticsData.ShippingDate" mode="date">
            <template v-slot="{ inputValue, inputEvents }">
              <CInput placeholder="YYYY-MM-DD" :value="inputValue" v-on="inputEvents">
                <template #append-content>
                  <CIcon name="cil-calendar"/>
                </template>
              </CInput>
            </template>
          </v-date-picker>
        </CCol>
        <CCol tag="label" sm="3" class="col-form-label">
          預定送達日期
        </CCol>
        <CCol sm="9">
          <v-date-picker v-model="LogisticsData.AssignDate" mode="date">
            <template v-slot="{ inputValue, inputEvents }">
              <CInput placeholder="YYYY-MM-DD" :value="inputValue" v-on="inputEvents">
                <template #append-content>
                  <CIcon name="cil-calendar"/>
                </template>
              </CInput>
            </template>
          </v-date-picker>
        </CCol>
      </CRow>
<!--      <CSelect horizontal :label="'溫層'" :options="TemperatureOptions" v-model="LogisticsData.Temperature" :value.sync="LogisticsData.Temperature" :placeholder="$t('Global.PleaseSelect')"/>-->
      <CInput id="Weight" :label="$t('Logistics.Weight')" type="number" v-model="LogisticsData.Weight" :placeholder="$t('Message.Order/WeightEmpty')" horizontal>
        <template #append-content>KG</template>
      </CInput>
      <CInput id="NetWeight" :label="$t('Logistics.NetWeight')" type="number" v-model="LogisticsData.NetWeight" :placeholder="$t('Message.Order/WeightEmpty')" horizontal>
        <template #append-content>KG</template>
      </CInput>
      <CRow v-if="LogisticsFeatures.includes('Packing')" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          使用包裹設定<i class="fas fa-info-circle text-info ml-2" v-c-tooltip="{content: '選擇此選項後包裹的體積將以「商店設定 > 包裹裝箱設定」之設定的紙箱進行自動匹配合適的包裝，若有未完成包裝的紙箱將無法進行拋單。', placement: 'right'}" />
        </CCol>
        <CCol sm="9" class="d-flex align-items-center">
          <CSwitch color="info" :checked.sync="LogisticsData.NeedPacking"/>
        </CCol>
      </CRow>
      <template v-if="!LogisticsData.NeedPacking">
        <CInput id="Length" :label="$t('Logistics.Length')" type="number" v-model="LogisticsData.Length" :placeholder="$t('Message.Order/LengthEmpty')" horizontal>
          <template #append-content>CM</template>
        </CInput>
        <CInput id="Width" :label="$t('Logistics.Weight')" type="number" v-model="LogisticsData.Width" :placeholder="$t('Message.Order/WidthEmpty')" horizontal>
          <template #append-content>CM</template>
        </CInput>
        <CInput id="Height" :label="$t('Logistics.Height')" type="number" v-model="LogisticsData.Height" :placeholder="$t('Message.Order/HeightEmpty')" horizontal>
          <template #append-content>CM</template>
        </CInput>
      </template>
      <CInput id="Quantity" :label="$t('Logistics.Quantity')" type="number" v-model="LogisticsData.Quantity" horizontal>
        <template #append-content>PCS</template>
      </CInput>
      <CInput :label="'商品名稱'" v-model="LogisticsData.ItemName" horizontal/>
      <CInput :label="'金額'" v-model="LogisticsData.Amount" horizontal/>
      <CTextarea :label="'備註'" v-model="LogisticsData.Remark" horizontal rows="5"/>
    </template>
    <CElementCover v-if="(Loading === true || Submit === true)" :opacity="0.75" class="py-5">
      <CSpinner color="info"/>
    </CElementCover>
    <template #header-wrapper>
      <header class="modal-header border-bottom-0">
        <h5 class="modal-title">{{ $t('Order.CreateLogisticsOrder') }}</h5>
        <CButtonClose @click="HandleHideModal()"/>
      </header>
    </template>
    <template #footer-wrapper>
      <footer class="modal-footer border-top-0">
        <CButton @click="HandleHideModal()" color="light">{{ $t('Global.Cancel') }}</CButton>
        <CButton id="CreateLogisticsOrderSubmit" @click="CreateLogisticsOrder()" color="success">
          {{ $t('Global.Confirm') }}
        </CButton>
      </footer>
    </template>
  </CModal>
</template>

<script>
export default {
  name: "CreateUcfuShipLogisticsOrder",
  props: {
    Toggle: {
      type: Boolean,
      default: false
    },
    OrderNum: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      Submit: false,
      Loading: false,
      LogisticsData: {
        Temperature: 'Common',
        MemberInfo: {}
      },
      Data: {
        Payment: {},
        Logistics: {},
        Invoice: {},
        MemberInfo: {},
        Variable: {},
        ProductInfo: []
      },
      LogisticsFeatures: []
    }
  },
  computed: {
    CountryOptions() {
      // eslint-disable-next-line no-unused-vars
      return Object.keys(this.$t('Logistics.CountryOptions')).filter(item => item => !item.includes('TW')).map(option => {
        return {
          value: option,
          label: this.$t('Logistics.CountryOptions')[option]
        }
      })
    },
    TemperatureOptions() {
      return Object.keys(this.$t('Logistics.TemperatureOptions')).map(status => {
        return {
          value: status,
          label: this.$t('Logistics.TemperatureOptions')[status]
        }
      })
    },
    DistanceOptions() {
      if (this.Data.Logistics.System) {
        return []
      } else {
        return Object.keys(this.$t('Order.LogisticsOrderOption.' + this.Data.Logistics.System + '.Distance')).map(status => {
          return {
            value: status,
            label: this.$t('Order.LogisticsOrderOption.' + this.Data.Logistics.System + '.Distance')[status]
          }
        })
      }
    }
  },
  created() {
    this.LogisticsFeatures = this.$store.state.user.permission.Features.System.Logistics || []
  },
  mounted() {
  },
  watch: {
    Toggle(value) {
      if (value) this.GerOrder()
    }
  },
  methods: {
    HandleHideModal() {
      this.Loading = true
      this.$emit('update:Toggle', false)
      this.$emit('update:OrderNum', false)
      this.LogisticsData = {
        Temperature: 'Common',
        MemberInfo: {}
      }
      this.Data = {
        Payment: {},
        Logistics: {},
        Invoice: {},
        MemberInfo: {},
        Variable: {},
        ProductInfo: []
      }
    },
    GerOrder() {
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/order/detail',
        method: 'get',
        params: {
          orderNum: this.OrderNum
        }
      }).then(async ({data}) => {
        this.Loading = false
        const Logistics = await this.GetCurrentLogistics(data.detail.Logistics.ID)
        this.Data = {
          ...data.detail,
          Logistics: {
            ...data.detail.Logistics,
            ...Logistics
          }
        }
        this.LogisticsData = {
          MerchantOrderNo: data.detail.OrderNum,
          MemberInfo: {
            ...data.detail.MemberInfo,
            ReceiverCountry: this.Data.Logistics.ShipmentCountry
          },
          Method: data.detail.Logistics.Method,
          Amount: data.detail.TotalAmount.toString(),
          ShipperInfo: this.Data.Logistics.ShipperInfo || {},
          ProductInfo: data.detail.ProductInfo,
          Quantity: '1',
          Weight: data.detail.ProductInfo.map(item => parseFloat(item.Variable.Weight)).reduce((a, c) => a + c, 0),
          NetWeight: data.detail.ProductInfo.map(item => parseFloat(item.Variable.Weight)).reduce((a, c) => a + c, 0),
          ItemName: this.Data.Logistics.ShipperInfo.DefaultProductName || '商品一批',
          Description: '',
          Distance: '00',
          ScheduledPickupTime: '4',
          Temperature: data.detail.Logistics.Detail ? (data.detail.Logistics.Detail.Temperature || 'Common') : 'Common',
          ShippingDate: this.$dayjs().format('YYYY-MM-DD'),
          AssignDate: '',
          Remark: ''
        }
      }).catch((error) => {
        this.Loading = false
        throw error
      })
    },
    GetCurrentLogistics(id) {
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/system/logistics/get/' + id,
        method: 'get'
      }).then(({data}) => {
        this.Loading = false
        return data
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    CreateLogisticsOrder() {
      // 商品列表重新處理 (拋單不使用訂單列表內的商品列表)
      this.LogisticsData.ProductInfo = [
        {
          Name: this.LogisticsData.ItemName,
          Quantity: '1',
          Amount: {
            Suggest: this.LogisticsData.Amount,
            Actual: this.LogisticsData.Amount,
            Cost: this.LogisticsData.Amount
          },
          Variable: {
            NetWeight: this.LogisticsData.NetWeight,
            Weight: this.LogisticsData.Weight
          }
        }
      ]
      if (!this.LogisticsData.NeedPacking) {
        this.Data.Packing = {
          Packages: [
            {
              CurrentPacking: {
                MaxWeight: this.LogisticsData.Weight,
                Length: this.LogisticsData.Length,
                Width: this.LogisticsData.Width,
                Height: this.LogisticsData.Height
              }
            }
          ]
        }
      }
      this.Submit = true
      this.$Progress.start()
      return this.$store.dispatch('InnerRequest', {
        url: '/order/createLogisticsOrder',
        method: 'post',
        data: {
          ...this.LogisticsData,
          Logistics: this.Data.Logistics,
          IsPayment: this.Data.Payment.Method !== 'CASH_DELIVERY',
          Packing: this.Data.Packing
        }
      }).then(({data}) => {
        this.Submit = false
        this.$Progress.finish()
        this.$emit('Success', data.detail)
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.Order/CreateLogisticsOrderSuccess'),
          type: 'success'
        })
        this.HandleHideModal()
      }).catch((error) => {
        console.log(error)
        this.Submit = false
        this.$Progress.fail()
        this.$emit('Error', error)
        this.HandleHideModal()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Order/CreateLogisticsOrderFail') + error.msg,
          type: (error.type ? error.type : 'error')
        })
      })
    }
  }
}
</script>

<style lang="scss">
#CreateLogisticsOrder {
  label {
    font-weight: bold;
  }
}
</style>
