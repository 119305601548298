<template>
  <CModal id="CreateHCTLogisticsOrder" class="ActionModel" :show.sync="Toggle" :centered="true" :closeOnBackdrop="!Submit"
          @update:show="HandleHideModal">
    <template v-if="!Loading">
      <CRow form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Order.LogisticsSystem') }}
        </CCol>
        <CCol sm="9" class="d-flex align-items-center">
          {{ $t('Logistics.LogisticsSystem.' + Data.Logistics.System) }}
        </CCol>
      </CRow>
      <CRow form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Order.LogisticsType') }}
        </CCol>
        <CCol sm="9" class="d-flex align-items-center">
          {{ $t('Logistics.LogisticsMethods.' + Data.Logistics.Method) }}
        </CCol>
      </CRow>
      <CInput :label="'商品名稱'" v-model="LogisticsData.ItemName" horizontal/>
      <CInput :label="'收件人'" v-model="LogisticsData.MemberInfo.ReceiverName" horizontal/>
      <CInput :label="'收件人Email'" v-model="LogisticsData.MemberInfo.ReceiverEmail" horizontal/>
      <template v-if="Data.Logistics.ServiceLocation === 'ABROAD'">
        <CSelect horizontal :label="'收件人國家'" :options="CountryOptions" v-model="LogisticsData.MemberInfo.ReceiverCountry" :value.sync="LogisticsData.MemberInfo.ReceiverCountry" :placeholder="$t('Global.PleaseSelect')"/>
        <CInput id="ReceiverCity" :label="'收件人城市'" v-model="LogisticsData.MemberInfo.ReceiverCity" horizontal/>
        <CInput id="ReceiverArea" :label="'收件人地區/州'" v-model="LogisticsData.MemberInfo.ReceiverArea" horizontal/>
      </template>
      <template v-if="Data.Logistics.Type === 'EXPRESS'">
        <CInput id="ReceiverZipCode" :label="'收件人郵遞區號'" v-model="LogisticsData.MemberInfo.ReceiverZipCode" horizontal/>
        <CInput id="ReceiverAddress" :label="'收件人地址'" v-model="LogisticsData.MemberInfo.ReceiverAddress" horizontal/>
        <CRow form class="form-group mb-0">
          <CCol tag="label" sm="3" class="col-form-label">
            預定配送日期
          </CCol>
          <CCol sm="9">
            <v-date-picker v-model="LogisticsData.ShippingDate" mode="date">
              <template v-slot="{ inputValue, inputEvents }">
                <CInput placeholder="YYYY-MM-DD" :value="inputValue" v-on="inputEvents">
                  <template #append-content>
                    <CIcon name="cil-calendar"/>
                  </template>
                </CInput>
              </template>
            </v-date-picker>
          </CCol>
          <CCol tag="label" sm="3" class="col-form-label">
            預定送達日期
          </CCol>
          <CCol sm="9">
            <v-date-picker v-model="LogisticsData.AssignDate" mode="date">
              <template v-slot="{ inputValue, inputEvents }">
                <CInput placeholder="YYYY-MM-DD" :value="inputValue" v-on="inputEvents">
                  <template #append-content>
                    <CIcon name="cil-calendar"/>
                  </template>
                </CInput>
              </template>
            </v-date-picker>
          </CCol>
        </CRow>
      </template>
      <CSelect horizontal :label="'溫層'" :options="TemperatureOptions" v-model="LogisticsData.Temperature" :value.sync="LogisticsData.Temperature" :placeholder="$t('Global.PleaseSelect')"/>
      <div>
        <CInput id="Weight" :label="'重量'" type="number" v-model="LogisticsData.Weight" :placeholder="$t('Message.Order/WeightEmpty')" horizontal>
          <template #append-content>KG</template>
        </CInput>
        <CInput id="Length" :label="'長度'" type="number" v-model="LogisticsData.Length" :placeholder="'若空白則以目前訂單內的商品長度計算'" horizontal>
          <template #append-content>CM</template>
        </CInput>
        <CInput id="Width" :label="'寬度'" type="number" v-model="LogisticsData.Width" :placeholder="'若空白則以目前訂單內的商品寬度計算'" horizontal>
          <template #append-content>CM</template>
        </CInput>
        <CInput id="Height" :label="'高度'" type="number" v-model="LogisticsData.Height" :placeholder="'若空白則以目前訂單內的商品高度計算'" horizontal>
          <template #append-content>CM</template>
        </CInput>
        <CInput id="Quantity" :label="'件數'" type="number" v-model="LogisticsData.Quantity" horizontal>
          <template #append-content>PCS</template>
        </CInput>
      </div>
      <CInput v-if="Data.Logistics.Type === 'SUPERSTORE'" :label="'退貨門市店號'" v-model="LogisticsData.ReturnStoreID" horizontal/>
      <CRow v-if="Data.Logistics.Type === 'SUPERSTORE'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          門市店號
        </CCol>
        <CCol sm="9">
          {{ LogisticsData.ReceiverStoreID }}
        </CCol>
      </CRow>
      <CRow v-if="Data.Logistics.Type === 'SUPERSTORE'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          門市名稱
        </CCol>
        <CCol sm="9">
          {{ LogisticsData.StoreName }}
        </CCol>
      </CRow>
      <CRow v-if="Data.Logistics.Type === 'SUPERSTORE'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          門市地址
        </CCol>
        <CCol sm="9">
          {{ LogisticsData.StoreAddress }}
        </CCol>
      </CRow>
      <CRow v-if="Data.Logistics.Type === 'SUPERSTORE'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          門市電話
        </CCol>
        <CCol sm="9">
          {{ LogisticsData.StorePhone }}
        </CCol>
      </CRow>
      <template v-if="Data.Logistics.System === 'ECAPY-LOGISTICS'">
        <CSelect horizontal :label="'距離'" :options="DistanceOptions" v-model="LogisticsData.Distance" :value.sync="LogisticsData.Distance" :placeholder="$t('Global.PleaseSelect')"/>
      </template>
      <CTextarea :label="'備註'" v-model="LogisticsData.Remark" horizontal rows="5"/>
    </template>
    <CElementCover v-if="(Loading === true || Submit === true)" :opacity="0.75" class="py-5">
      <CSpinner color="info"/>
    </CElementCover>
    <template #header-wrapper>
      <header class="modal-header border-bottom-0">
        <h5 class="modal-title">{{ $t('Order.CreateLogisticsOrder') }}</h5>
        <CButtonClose @click="HandleHideModal()"/>
      </header>
    </template>
    <template #footer-wrapper>
      <footer class="modal-footer border-top-0">
        <CButton @click="HandleHideModal()" color="light">{{ $t('Global.Cancel') }}</CButton>
        <CButton id="CreateLogisticsOrderSubmit" @click="CreateLogisticsOrder()" color="success">
          {{ $t('Global.Confirm') }}
        </CButton>
      </footer>
    </template>
  </CModal>
</template>

<script>
export default {
  name: "CreateLogisticsOrder",
  props: {
    Toggle: {
      type: Boolean,
      default: false
    },
    OrderNum: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      Submit: false,
      Loading: false,
      LogisticsData: {
        MemberInfo: {}
      },
      Data: {
        Payment: {},
        Logistics: {},
        Invoice: {},
        MemberInfo: {},
        Variable: {},
        ProductInfo: []
      }
    }
  },
  computed: {
    CountryOptions() {
      return Object.keys(this.$t('Logistics.CountryOptions')).filter(item => item !== 'TW').map(option => {
        return {
          value: option,
          label: this.$t('Logistics.CountryOptions')[option]
        }
      })
    },
    TemperatureOptions() {
      return Object.keys(this.$t('Logistics.TemperatureOptions')).map(status => {
        return {
          value: status,
          label: this.$t('Logistics.TemperatureOptions')[status]
        }
      })
    },
    DistanceOptions() {
      if (this.Data.Logistics.System) {
        return []
      } else {
        return Object.keys(this.$t('Order.LogisticsOrderOption.' + this.Data.Logistics.System + '.Distance')).map(status => {
          return {
            value: status,
            label: this.$t('Order.LogisticsOrderOption.' + this.Data.Logistics.System + '.Distance')[status]
          }
        })
      }
    }
  },
  mounted() {
  },
  watch: {
    Toggle(value) {
      if (value) this.GerOrder()
    }
  },
  methods: {
    HandleHideModal() {
      this.$emit('update:Toggle', false)
      this.$emit('update:OrderNum', false)
      this.LogisticsData = {
        MemberInfo: {}
      }
      this.Data = {
        Payment: {},
        Logistics: {},
        Invoice: {},
        MemberInfo: {},
        Variable: {},
        ProductInfo: []
      }
    },
    GerOrder() {
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/order/detail',
        method: 'get',
        params: {
          orderNum: this.OrderNum
        }
      }).then(async ({data}) => {
        this.Loading = false
        this.Data = {
          ...data.detail,
          Logistics: await this.GetCurrentLogistics(data.detail.Logistics.ID)
        }
        this.LogisticsData = {
          MerchantOrderNo: data.detail.OrderNum,
          MemberInfo: {
            ...data.detail.MemberInfo,
            ReceiverCountry: this.Data.Logistics.ShipmentCountry
          },
          Method: data.detail.Logistics.Method,
          Amount: data.detail.TotalAmount.toString(),
          ShipperInfo: this.Data.Logistics.ShipperInfo || {},
          ProductInfo: data.detail.ProductInfo,
          Quantity: '1',
          Weight: '',
          ItemName: this.Data.Logistics.ShipperInfo.DefaultProductName || '商品一批',
          Description: '',
          Distance: '00',
          ScheduledPickupTime: '4',
          Temperature: data.detail.Logistics.Detail ? data.detail.Logistics.Detail.Temperature : 'Common',
          ShippingDate: this.$dayjs().format('YYYY-MM-DD'),
          AssignDate: this.$dayjs().add(7, 'day').format('YYYY-MM-DD'),
          Remark: ''
        }
      }).catch((error) => {
        this.Loading = false
        throw error
      })
    },
    GetCurrentLogistics(id) {
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/system/logistics/get/' + id,
        method: 'get'
      }).then(({data}) => {
        this.Loading = false
        return data
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    CreateLogisticsOrder() {
      this.Submit = true
      this.$Progress.start()
      return this.$store.dispatch('InnerRequest', {
        url: '/order/createLogisticsOrder',
        method: 'post',
        data: {
          ...this.LogisticsData,
          Logistics: this.Data.Logistics,
          IsPayment: this.Data.Payment.Method !== 'CASH_DELIVERY'
        }
      }).then(({data}) => {
        this.Submit = false
        this.$Progress.finish()
        this.$emit('Success', data.detail)
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.Order/CreateLogisticsOrderSuccess'),
          type: 'success'
        })
        this.HandleHideModal()
      }).catch((error) => {
        this.Submit = false
        this.$Progress.fail()
        this.$emit('Error', error)
        this.HandleHideModal()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Order/CreateLogisticsOrderFail') + error.msg,
          type: (error.type ? error.type : 'error')
        })
      })
    }
  }
}
</script>

<style lang="scss">
#CreateLogisticsOrder {
  label {
    font-weight: bold;
  }
}
</style>
